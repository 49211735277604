html {
  height: 100%;
}

body {
  height: 100%;
  background-color: #e7e7e7;
}

#root {
  height: 100%;
}

:root {
  --toastify-color-info: #6315e3;
}

/* Scrollbar Width */
::-webkit-scrollbar {
  width: 7px;
}

/* Scrollbar Track */
::-webkit-scrollbar-track {
  background: whiteSmoke;
}

/* Scrollbar Handle */
::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background: lightGrey;
}
